/* Criando função para a diretiva */

/*
 1: Essa linha de código está verificando se o elemento em que a diretiva é aplicada é um 
    elemento "input". Se for, a variável "inputEl" recebe a referência a esse elemento. 
    Caso contrário, a variável "inputEl" recebe a referência ao primeiro elemento "input"
    encontrado dentro do elemento em que a diretiva é aplicada.
*/
function contagemCaractesInput(App) {
    App.directive('contagemCaracter', {
        beforeMount(el, binding) {
/* 1 */     const inputEl = el.tagName === 'INPUT' ? el : el.querySelector('input');
            if (!inputEl) {
                console.error('Directive "count-characters" requires an input element.');
                return;
            }
            inputEl.addEventListener('input', () => {
                const inputValue = inputEl.value;
                const inputLength = inputValue.length;
                if (inputLength < binding.value) {
                    inputEl.style.borderColor = 'red';
                } else {
                    inputEl.style.borderColor = 'green';
                }
            });
        }
    })
}

/* Exportando um objeto com as funções */
export const diretivas = {
    contagemCaractesInput
}